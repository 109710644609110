import React, {useState} from "react";
import * as styles from "./ContactForm.module.scss";
import {getLabel} from "../contentful/data-provider";

const ContactForm = ({labels}) => {

    const [checked, setChecked] = useState(false);

    return (
        <form
            action='https://getform.io/f/5e0e0498-6f23-4941-bd71-38cc91207776'
            encType='multipart/form-data'
            method={"post"}
            className={styles.container}
        >
            <div className={styles.grid}>

                {/* NAME */}
                <div className={styles.inputContainer}>
                    <label htmlFor='name' className={styles.label}>
                        {getLabel('form-name-label', labels)}
                    </label>
                    <input id='name' name='name' className={styles.input} type='text' required={true}/>
                </div>


                {/* COMPANY */}
                <div className={styles.inputContainer}>
                    <label htmlFor='company' className={styles.label}>
                        {getLabel('form-company-label', labels)}
                    </label>
                    <input id='company' name='company' className={styles.input} type='text' required={true}/>
                </div>


                {/* EMAIL */}
                <div className={styles.inputContainer}>
                    <label htmlFor='email' className={styles.label}>
                        {getLabel('form-email-label', labels)}
                    </label>
                    <input id='email' name='email' className={styles.input} type='email' required={true}/>
                </div>


                {/* PHONE */}
                <div className={styles.inputContainer}>
                    <label htmlFor='phone' className={styles.label}>
                        {getLabel('form-phone-label', labels)}
                    </label>
                    <input id='phone' name='phone' className={styles.input} type='tel' required={true}/>
                </div>
            </div>


            {/* MESSAGE */}
            <div className={styles.textAreaContainer}>
                <label htmlFor='message' className={styles.label}>
                    {getLabel('form-message-label', labels)}
                </label>
                <textarea id='message' name='message' className={styles.textArea} type='email' rows='10'
                          required={true}/>
            </div>


            {/* CHECKBOX */}
            <div className={styles.inputContainer}>
                <label className={styles.checkboxLabel}>
                    {getLabel("agree-privacy-policy-label", labels)}
                    <input
                        id='checkbox'
                        name='checkbox'
                        className={styles.checkbox}
                        type='checkbox'
                        required={true}
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                        value={checked ? "Terms accepted" : "Terms not accepted"}
                    />
                    <span className={styles.checkmark}>✓</span>
                </label>
            </div>
            <div>
                <input type='hidden' name='_gotcha' className={styles.hidden}/>
            </div>
            <button type={"submit"} className={styles.button}>
                {getLabel("send-message-label", labels)}
            </button>
        </form>
    );
};

export default ContactForm;
